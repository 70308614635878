export const REGEX = {
  IS_NUMBER: /^[0-9]+$/,
  NUMBER_DECIMAL: /^\d*\.?\d*$/,
  NUMBER_INTEGER: /^[1-9][0-9]*$/,
  TIME: /^([01]\d|2[0-3]):?([0-5]\d)$/,
  NUMBER_ONE_DECIMAL: /^(\d+)?([.]?\d{0,1})?$/,
};

export const MODAL_TYPE = {
  CREATE: 'CREATE',
  EDIT: 'EDIT',
  DETAIL: 'DETAIL',
  COPY: 'COPY',
};

export const QUESTION_TYPES = {
  SINGLE: 1,
  MULTIPLE: 2,
  TEXT: 3,
  RATING: 4,
};

export const MODAL_STATUS = {
  UNCHANGE: 0,
  CHANGED: 1,
  DELETE: 2,
  NEW: 3,
};

export const SUB_CODE = {
  KS: 'KS',
  BH: 'BH',
};

export const ALLOW_FILE_VIDEO_TYPE = [
  'video/x-msvideo', // avi
  'video/avi', // avi
  'video/mp4', //mp4
  'video/quicktime', //mov
  'video/x-quicktime', //mov
];

export const ALLOW_FILE_AUDIO_TYPE = ['audio/mpeg', 'audio/wav'];

export const ACCOUNT_RULE_TYPE = {
  GENDER: 0,
  PERSONALITY: 1,
  INTEREST: 2,
  FAVORITE_SPORT: 3,
  CONSCIOUSNESS_PRACTICE: 4,
  LOCALLY: 5,
  LANGUAGE: 6,
  RELIGION: 7,
  VAGETARIAN: 8,
  WORKING_HOURS: 9,
  LEVEL_DIABETES: 10,
  DEPTH_EXPERIENCE: 11,
};

export const TAG_TYPE = {
  AGENCY: 3,
  SPORT: 4,
  RELIGION: 5,
};

export const CALENDAR_TYPE = {
  OUTPUT_CLASSIFICATION: {
    value: '0',
    color: '#FDB913',
  },
  INPUT_RATING: {
    value: '1',
    color: '#01645A',
  },
  ONE_ONE: {
    value: '2',
    color: '#21A567',
  },
  ONE_MULTIPLE: {
    value: '3',
    color: '#6D28D9',
  },
  LIVESTREAM: {
    value: '4',
    color: '#AF0000',
  },
  OTHER: {
    value: '5',
    color: '#F58220',
  },
};

export const PAGE_CODE = {
  FOOD_MENU: 'FoodMenu',
  ACCOUNT_ROLE: 'AccountRole',
  ACCOUNT_ACCOUNT_PORTAL: 'AccountAccountPortal',
  ACCOUNT_ACCOUNT_APP: 'AccountAccountApp',
  LESSON_LEVEL: 'LessonLevel',
  LESSON_MODULE: 'LessonModule',
  LESSON: 'Lesson',
  QUIZ: 'Quiz',
  SURVEY: 'Survey',
  SURVEY_QUESTION_GROUP: 'SurveyQuestionGroup',
  CALENDAR: 'Calendar',
  TRAINING_GROUP: 'TrainingGroup',
  EMOTION: 'Emotion',
  FOOD_CATEGORY: 'FoodCategory',
  EXERCISE: 'Exercise',
  PROFILE_INSTRUCTION: 'ProfileInstruction',
  TIME_FRAME: 'TimeFrame',
  BMI: 'Bmi',
  GLUCOSE: 'Glucose',
  COMMON_CONFIGURE_TASK_CONTENT: 'CommonConfigureTaskContent',
  COMMON_CONFIGURE_TASK_PARAMETER: 'CommonConfigureTaskParameter',
  COMMUNICATION: 'Communication',
  CUSTOMER_GROUP: 'CustomerGroup',
  LEARNING_POST: 'LearningPost',
  AGENDA: 'Agenda',
  QUESTION: 'Question',
  MY_CUSTOMER: 'MyCustomer',
  USER_DASHBOARD: 'UserDashboard',
  COACHES_EXPERTS: 'CoachesExperts',
  VOUCHERS: 'Vouchers',
  TRACKING_SHARE_APP: 'TrackingShareApp',
  ZOOM_LOGIN: 'ZoomLogin',
};

export const PERMISSION_TYPE = {
  READ: 0,
  WRITE: 1,
  READ_ALL: 2,
  WRITE_ALL: 3,
  READ_OWNER: 4,
  WRITE_OWNER: 5,
  WRITE_1_PART: 8,
};

export const CALENDAR_REPEAT_TYPE = {
  NO_REPEAT: '0',
  DAILY: '1',
  WEEKLY: '2',
  MONTHLY: '3',
  CUSTOM: '4',
};

export const CALENDAR_CANCEL = {
  CURRENT: 0,
  CURRENT_AND_NEXT: 1,
  ALL: 2,
};

export const POSITIONS = ['CoachManager', 'HeadCoach', 'Coach'];
export const EXCEL_FILE_TYPE = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
];
export const PDF_FILE_TYPE = ['application/pdf'];

export const SCHEDULE_TYPES = {
  MOVEMENT_EXERCISES: 1,
  FREE: 2,
  KPI: 3,
  COMMON: 4,
  LESSON: 5,
};

export const ACCOUNT_TYPES = {
  NEW_CUSTOMER: 1,
  AWAIT_COACHING: 2,
};

export const ACTIVE_ACCOUNT_STATUS = {
  NO_ACCOUNT: 1,
  WAITING_ACTIVE: 2,
  ACTIVED: 3,
  WAITING_COACHING: 6,
  WAITING_ASSIGN_GROUP: 7,
  ASSIGNED_GROUP: 8,
};

export const ACTIVE_ACCOUNT_STATUS_TAG = {
  BOUGHT_NEW_PACKAGE: 1,
  WAITING_EFFECT: 2,
};

export const QUESTION_STATUSES = {
  CLOSE: 0,
  WAITING: 1,
  ANSWERED: 2,
  OPEN: 3,
};
export const INPROGRESS_TYPES = {
  IMPROVE: 0,
  STOP_STUDY: 1,
  SUPPORT: 2,
  END: 3,
  NEW: 4,
  ONE_ONE: 5,
  ONE_N: 6,
  HIGHT_BLOOD_SUGAR: 7,
  LOW_BLOOD_SUGAR: 8,
  TOTAL: 9,
};

export const SURVEY_ANSWER_STATUS = {
  UNFINISH: 0,
  INPROGRESS: 1,
  FINISHED: 2,
};

export const STUDY_PROGRESS_TYPES = {
  NEED_IMPROVE: 1,
  PENDING: 2,
  IMPROVING: 3,
  RIGHT_WAY: 4,
  GOOD: 5,
  VERY_GOOD: 6,
};
export const SUPORT_TYPES = {
  NORMAL: 1,
  PREFER: 2,
};
export const CALENDAR_TYPES = {
  OUTPUT_RATING: 0,
  INPUT_RATING: 1,
  COACH_11: 2,
  COACH_1N: 3,
  DOCTOR_COMMENT: 4,
};

export const SUMMARY_TYPES = {
  ABLE_MAINTAIN_LIFESTYLE: 0,
  BUY_PACKAGE: 1,
  CONTINUTE_LEARN: 2,
};

export const TARGET_TYPES = {
  FREE: 0,
  BLOOD_PRESSURE: 1,
  BLOOD_SUGAR: 2,
  WEIGHT: 4,
  EMOTION: 5,
  FOOD: 6,
  EXERCISE: 3,
  SURVEY: 12,
  EXERCISE_MOVEMENT: 7,
  BOOK_1_1: 8,
  BOOK_1_n: 9,
  INPUT_RATING: 10,
  OUTPUT_RATING: 14,
  LESSON: 11,
  UPDATE_PROFILE: 13,
};

export const TARGET_REPEAT_TYPES = {
  DAILY: 0,
  WEEKLY: 1,
  NO_REPEAT: null,
};

export const LESSON_ACCOUNT_TYPES = {
  NOT_COMPLETE: 0,
  COMPLETE: 1,
  STUDYING: 2,
  LOCK: 3,
  NOT_ACCESS: 4,
};

export const TARGET_STATUS = {
  COMPLETED: 1,
  IN_PROGRESS: 2,
};

export const AGENDA_COMMON_TYPE = {
  PERSONAL_INTERACTION: 1,
  GROUP_INTERACTION: 2,
  SURVEY: 3,
  OUTPUT_RATING: 4,
  UPDATE_PROFILE: 5,
};

export const CONTAINER_FILE_NAME = {
  EXERCISE_MOVEMENT: 'BTVD',
  LESSON: 'BaiHoc',
  EXERCISE: 'Van Dong',
};
